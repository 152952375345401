/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';

import {useMessagingService} from './MessagingServiceContext';
import ToggleTile from './components/ToggleTile';
import {CpuLimiterInfo} from './FeatureInformation';
import FeatureUpdateMessage from './Messages';

import headerIcon from './img/limiterTile/cpuLimiterTitle.svg';
import mainDisabledIcon from './img/limiterTile/cpuLimiterDisabledMain.svg';
import mainEnabledIcon from './img/limiterTile/cpuLimiterEnabledMain.svg';

const CpuLimiterTile = props => {
  const {updateFeatureState, sendMessageToExtension} = useMessagingService();

  const handleToggle = newState => {
    const id = CpuLimiterInfo.id;
    updateFeatureState(id, newState);
    const message = new FeatureUpdateMessage(id, newState);
    sendMessageToExtension(message);
  };

  return (
    <ToggleTile
      title={CpuLimiterInfo.label}
      icon={headerIcon}
      mainIconDisabled={mainDisabledIcon}
      mainIconEnabled={mainEnabledIcon}
      statusText={{enabled: 'ENABLED', disabled: 'DISABLED'}}
      isEnabled={props.enabled}
      onToggle={handleToggle}
    />
  );
};

export default CpuLimiterTile;
