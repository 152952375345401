/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';
import styles from './Toggle.module.css';

const Toggle = ({checked, onClick}) => {
  return (
    <div
      className={`${styles.toggle} ${checked ? styles.enabled : ''}`}
      onClick={onClick}
    >
      <div
        className={`${styles.button} ${checked ? styles.enabled : ''}`}
      ></div>
    </div>
  );
};

export default Toggle;
