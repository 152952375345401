/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';

import {useMessagingService} from './MessagingServiceContext';
import Button from './components/Button';
import {TabSlasherInfo} from './FeatureInformation';
import TileHeader from './components/TileHeader';
import FeatureUpdateMessage from './Messages';

import styles from './TabSlasherTile.module.css';
import tileStyles from './components/Tile.module.css';

import headerIcon from './img/tabSlasherTitle.svg';
import mainIcon from './img/tabSlasherMain.svg';

const TabSlasherTile = props => {
  const {sendMessageToExtension} = useMessagingService();

  const handleKillAll = () => {
    if (navigator.vibrate) {
      navigator.vibrate(40);
    }
    if (props.tabs === 0) {
      return;
    }
    const message = new FeatureUpdateMessage(TabSlasherInfo.id);
    sendMessageToExtension(message);
  };

  return (
    <div className={tileStyles.tile}>
      <TileHeader
        title={TabSlasherInfo.label}
        icon={headerIcon}
      />
      <div className={tileStyles.main}>
        <div className={styles.info}>
          <img
            className={styles.mainIcon}
            src={mainIcon}
            alt={`${TabSlasherInfo.label} Icon`}
          />
          <div className={styles.tabsCount}>{props.tabs}</div>
        </div>
        <div className={styles.label}>OPENED TABS</div>
      </div>
      <div className={tileStyles.bottom}>
        <Button onClick={handleKillAll}>KILL'EM ALL</Button>
      </div>
    </div>
  );
};

export default TabSlasherTile;
