/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

export const CpuLimiterInfo = {
  id: 'cpu-limiter',
  label: 'CPU Limiter',
  default_value: false,
};

export const RamLimiterInfo = {
  id: 'ram-limiter',
  label: 'RAM Limiter',
  default_value: false,
};

export const NetworkLimiterInfo = {
  id: 'network-limiter',
  label: 'Network Limiter',
  default_value: false,
};

export const PanicButtonInfo = {
  id: 'panic-button',
  label: 'Panic Button',
  default_value: false,
};

export const SoundsInfo = {
  id: 'sounds',
  label: 'Sounds and Music',
  default_value: false,
};

export const TabSlasherInfo = {
  id: 'tab-slasher',
  label: 'Tab Slasher',
  default_value: 0,
};

export const FeaturesInfo = [
  CpuLimiterInfo,
  RamLimiterInfo,
  NetworkLimiterInfo,
  PanicButtonInfo,
  SoundsInfo,
  TabSlasherInfo,
];
