/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';
import styles from './Tile.module.css';

const TileHeader = ({title, icon}) => {
  return (
    <div className={styles.top}>
      <img className={styles.icon} src={icon} alt={`${title} Icon`} />
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default TileHeader;
