/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';

import styles from './Tile.module.css';
import TileHeader from './TileHeader';
import Toggle from './Toggle';

const ToggleTile = ({
  title,
  icon,
  mainIconDisabled,
  mainIconEnabled,
  statusText,
  isEnabled,
  onToggle,
}) => {
  const handleClick = () => {
    const newState = !isEnabled;
    onToggle(newState);
    if (navigator.vibrate) {
      navigator.vibrate(20);
    }
  };

  return (
    <div className={`${styles.tile} ${isEnabled ? styles.enabled : ''}`}>
      <TileHeader title={title} icon={icon} />
      <div className={styles.main}>
        <img
          className={styles.mainIcon}
          src={isEnabled ? mainIconEnabled : mainIconDisabled}
          alt={`${title} Icon`}
        />
      </div>
      <div className={styles.bottom}>
        <div className={styles.status}>
          {isEnabled ? statusText.enabled : statusText.disabled}
        </div>
        <Toggle checked={isEnabled} onClick={handleClick} />
      </div>
    </div>
  );
};

export default ToggleTile;
