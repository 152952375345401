/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React, {createContext, useState, useContext, useEffect} from 'react';
import {FeaturesInfo} from './FeatureInformation';

const SettingsContext = createContext();


const generateDefaultUserSettings = () => {
  return FeaturesInfo.reduce((acc, feature) => {
    acc[feature.id] = true;
    return acc;
  }, {});
}

const getInitialUserSettings = () => {
  const savedUserSettings = localStorage.getItem('userSettings');
  if (!savedUserSettings) {
    return generateDefaultUserSettings();
  }

  try {
    return JSON.parse(savedUserSettings);
  } catch (error) {
    console.error(error);
    return {};
  }
};

const getUpdatedUserSettings = (currentSettings, newSettings) => {
  const updatedSettings = {...currentSettings};

  Object.keys(newSettings).forEach(key => {
    if (!(key in currentSettings)) updatedSettings[key] = true;
  });

  return updatedSettings;
};

export const SettingsProvider = ({children}) => {
  const [userSettings, setUserSettings] = useState(getInitialUserSettings);

  useEffect(() => {
    localStorage.setItem('userSettings', JSON.stringify(userSettings));
  }, [userSettings]);

  const toggleSetting = id => {
    setUserSettings(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const resetSettings = () => {
    const defaultUserSettings = generateDefaultUserSettings();
    setUserSettings(defaultUserSettings);
    localStorage.removeItem('uuid');
  };

  const updateUserSettings = newComponents => {
    const updatedSettings = getUpdatedUserSettings(userSettings, newComponents);
    setUserSettings(updatedSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        userSettings,
        toggleSetting,
        resetSettings,
        updateUserSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingsContext);
};
