/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React, {useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import QrScanner from 'qr-scanner';
import styles from './ConnectPage.module.css';
import buttonStyles from './components/Button.module.css';
import StatusTopbar from './StatusTopBar';

const validateUrl = url => {
  try {
    const parsedUrl = new URL(url);
    return (
      parsedUrl.protocol === 'https:' &&
      parsedUrl.hostname.toLowerCase() === 'operagx.gg' &&
      ['/gx-connect', '/gx-connect/'].includes(parsedUrl.pathname.toLowerCase()) &&
      parsedUrl.searchParams.has('uuid')
    );
  } catch (err) {
    return false;
  }
};

const extractUuidFromUrl = url => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.searchParams.get('uuid');
  } catch (err) {
    return null;
  }
};

const ConnectPage = ({setUuid}) => {
  const videoRef = useRef(null);
  const [isScanning, setIsScanning] = useState(false);
  const navigate = useNavigate();

  const handleScanClick = async () => {
    if (isScanning) {
      return;
    }

    setIsScanning(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {facingMode: 'environment'},
      });

      const video = videoRef.current;
      video.srcObject = stream;
      video.setAttribute('playsinline', true);
      video.play();

      const qrScanner = new QrScanner(video, result => {
        qrScanner.stop();
        stream.getTracks().forEach(track => track.stop());
        setIsScanning(false);
        if (validateUrl(result)) {
          const scannedUuid = extractUuidFromUrl(result);
          if (scannedUuid) {
            setUuid(() => {
              return scannedUuid;
            });
            navigate('/', {replace: true});
          } else {
            alert('Please scan GX Connect QR Code');
          }
        } else {
          alert('Please scan GX Connect QR Code');
        }
      });
      qrScanner.start();
    } catch (err) {
      console.error(err);
      setIsScanning(false);
    }
  };

  return (
    <div className={styles.connectPage}>
      <StatusTopbar withSettings={false} />
      {!isScanning && (
        <button
          className={`${buttonStyles.primary} ${styles.button}`}
          onClick={handleScanClick}
        >
          SCAN QR CODE
        </button>
      )}
      <video
        ref={videoRef}
        className={styles.videoContainer}
        style={{display: isScanning ? 'block' : 'none'}}
      />
    </div>
  );
};

export default ConnectPage;
