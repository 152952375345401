/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';
import {Link} from 'react-router-dom';
import styles from './SettingsPage.module.css';
import Toggle from './components/Toggle';
import {FeaturesInfo} from './FeatureInformation';
import {useSettings} from './SettingsContext';
import buttonStyles from './components/Button.module.css';
import {useMessagingService} from './MessagingServiceContext';

import Subpage from './components/Subpage';

import cpuLimiterIcon from './img/limiterTile/cpuLimiterTitle.svg';
import ramLimiterIcon from './img/limiterTile/ramLimiterTitle.svg';
import networkLimiterIcon from './img/limiterTile/networkLimiterTitle.svg';
import panicButtonIcon from './img/panicButtonTitle.svg';
import tabSlasherIcon from './img/tabSlasherTitle.svg';
import soundsIcon from './img/soundsTitle.svg';
import logoHorizontal from './img/logoHorizontal.svg';
import licenseIcon from './img/info.svg';

const SettingsPage = () => {
  const {userSettings, toggleSetting, resetSettings} = useSettings();
  const {browserFeatures} = useMessagingService();

  const handleReset = () => {
    resetSettings();
    window.location.replace('/');
  };

  const featureIcons = {
    'cpu-limiter': cpuLimiterIcon,
    'ram-limiter': ramLimiterIcon,
    'network-limiter': networkLimiterIcon,
    'panic-button': panicButtonIcon,
    'tab-slasher': tabSlasherIcon,
    sounds: soundsIcon,
  };

  return (
    <Subpage title='Settings'>
      <div className={styles.items}>
        {browserFeatures &&
          FeaturesInfo.map(feature => {
            if (!browserFeatures.has(feature.id)) {
              return null;
            }

            const icon = featureIcons[feature.id];
            if (!icon) {
              return null;
            }

            return (
              <div className={styles.item} key={feature.id}>
                <div className={styles.header}>
                  <div className={styles.iconContainer}>
                    <img
                      src={icon}
                      alt={`${feature.label} Icon`}
                      className={styles.icon}
                    />
                  </div>
                  <div className={styles.title}>{feature.label}</div>
                </div>
                <Toggle
                  checked={!!userSettings[feature.id]}
                  onClick={() => toggleSetting(feature.id)}
                />
              </div>
            );
          })}
      </div>
      <div className={styles.resetButtonContainer}>
        <button className={buttonStyles.primary} onClick={handleReset}>
          RESET
        </button>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div className={styles.logoContainer}>
            <img
              src={logoHorizontal}
              alt='Opera GX Logo'
              className={styles.logoHorizontal}
            />
            <div className={styles.versionContainer}>
              <div className={styles.version}>Version: 0.1.0</div>
              <div className={styles.info}>
                <Link to='/legal'>
                  <img
                    src={licenseIcon}
                    alt='Info Icon'
                    className={styles.licenseIcon}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.disclaimer}>
            It may not be fully functional and could contain bugs. We appreciate
            your patience and feedback as we continue to improve it. By using
            this feature, you acknowledge that it is experimental and you agree
            to use it at your own risk.
          </div>
        </div>
      </div>
    </Subpage>
  );
};

export default SettingsPage;
