/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';

import {useMessagingService} from './MessagingServiceContext';
import ToggleTile from './components/ToggleTile';
import {NetworkLimiterInfo} from './FeatureInformation';
import FeatureUpdateMessage from './Messages';

import headerIcon from './img/limiterTile/networkLimiterTitle.svg';
import mainDisabledIcon from './img/limiterTile/networkLimiterDisabledMain.svg';
import mainEnabledIcon from './img/limiterTile/networkLimiterEnabledMain.svg';

const NetworkLimiterTile = props => {
  const {updateFeatureState, sendMessageToExtension} = useMessagingService();

  const handleToggle = newState => {
    updateFeatureState(NetworkLimiterInfo.id, newState);
    const message = new FeatureUpdateMessage(NetworkLimiterInfo.id, newState);
    sendMessageToExtension(message);
  };

  return (
    <ToggleTile
      title={NetworkLimiterInfo.label}
      icon={headerIcon}
      mainIconDisabled={mainDisabledIcon}
      mainIconEnabled={mainEnabledIcon}
      statusText={{enabled: 'ENABLED', disabled: 'DISABLED'}}
      isEnabled={props.enabled}
      onToggle={handleToggle}
    />
  );
};

export default NetworkLimiterTile;
