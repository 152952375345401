/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import styles from './HomePage.module.css';
import {useSettings} from './SettingsContext';
import {ConnectionStatus, useMessagingService} from './MessagingServiceContext';

import StatusTopBar from './StatusTopBar';

import CpuLimiterTile from './CpuLimiterTile';
import RamLimiterTile from './RamLimiterTile';
import NetworkLimiterTile from './NetworkLimiterTile';
import SoundsTile from './SoundsTile';
import TabSlasherTile from './TabSlasherTile';
import PanicButtonTile from './PanicButtonTile';
import useScreenWakeLock from './ScreenWakeLock';

import {
  CpuLimiterInfo,
  RamLimiterInfo,
  NetworkLimiterInfo,
  PanicButtonInfo,
  SoundsInfo,
  TabSlasherInfo,
} from './FeatureInformation';

const HomePage = () => {
  const {userSettings} = useSettings();
  const {browserFeatures, connectionStatus} = useMessagingService();
  const location = useLocation();

  useScreenWakeLock();

  useEffect(() => {
    if (location.search) {
      const newUrl = location.pathname;
      window.history.replaceState(null, '', newUrl);
    }
  }, [location]);

  const shouldShowTile = featureId => {
    return userSettings[featureId] && browserFeatures?.has(featureId);
  };

  return (
    <div className={styles.homePage}>
      <StatusTopBar
        withSettings={
          connectionStatus === ConnectionStatus.Connected ||
          connectionStatus === ConnectionStatus.Disconnected
        }
      />

      {(connectionStatus === ConnectionStatus.Connected ||
        connectionStatus === ConnectionStatus.Disconnected) && (
        <div className={styles.tilesContainer}>
          {shouldShowTile(NetworkLimiterInfo.id) && (
            <NetworkLimiterTile
              enabled={browserFeatures.get(NetworkLimiterInfo.id)}
            />
          )}
          {shouldShowTile(SoundsInfo.id) && (
            <SoundsTile muted={browserFeatures.get(SoundsInfo.id)} />
          )}
          {shouldShowTile(TabSlasherInfo.id) && (
            <TabSlasherTile tabs={browserFeatures.get(TabSlasherInfo.id)} />
          )}
          {shouldShowTile(PanicButtonInfo.id) && (
            <PanicButtonTile
              enabled={browserFeatures.get(PanicButtonInfo.id)}
            />
          )}
          {shouldShowTile(CpuLimiterInfo.id) && (
            <CpuLimiterTile enabled={browserFeatures.get(CpuLimiterInfo.id)} />
          )}
          {shouldShowTile(RamLimiterInfo.id) && (
            <RamLimiterTile enabled={browserFeatures.get(RamLimiterInfo.id)} />
          )}

          {connectionStatus === ConnectionStatus.Disconnected && (
            <div className={styles.overlay}>
              <div className={styles.overlayContent}>
                <h2>Opera GX not detected</h2>
                <p>Turn on Opera GX or reset settings</p>
              </div>
            </div>
          )}
        </div>
      )}

      {connectionStatus === ConnectionStatus.Connecting && (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}></div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
