/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';
import styles from './Button.module.css';

const Button = ({children, onClick, disabled = false}) => {
  return (
    <button
      className={`${styles.primary} ${disabled ? styles.disabled : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
