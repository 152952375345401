/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

class FeatureUpdateMessage {
  constructor(component, value = null) {
    this.type = 'update';
    this.component = component;
    if (value !== null) {
      this.value = value;
    }
  }
}

export default FeatureUpdateMessage;
