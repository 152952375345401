/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';

import {useMessagingService} from './MessagingServiceContext';
import ToggleTile from './components/ToggleTile';
import {SoundsInfo} from './FeatureInformation';
import FeatureUpdateMessage from './Messages';

import headerIcon from './img/soundsTitle.svg';
import mainDisabledIcon from './img/soundsDisabledMain.svg';
import mainEnabledIcon from './img/soundsEnabledMain.svg';

const SoundsTile = props => {
  const {updateFeatureState, sendMessageToExtension} = useMessagingService();

  const handleToggle = newState => {
    updateFeatureState(SoundsInfo.id, newState);
    const message = new FeatureUpdateMessage(SoundsInfo.id, newState);
    sendMessageToExtension(message);
  };

  return (
    <ToggleTile
      title={SoundsInfo.label}
      icon={headerIcon}
      mainIconDisabled={mainDisabledIcon}
      mainIconEnabled={mainEnabledIcon}
      statusText={{enabled: 'MUTED', disabled: 'UNMUTED'}}
      isEnabled={props.muted}
      onToggle={handleToggle}
    />
  );
};

export default SoundsTile;
